import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import WorkList from '../components/WorkList'
import WorkCategoriesMenu from '../components/WorkCategoriesMenu'

const Works = ({ data, location }) => {

  return (
    <Layout location={location}>
      <Helmet title="Works" />
      <WorkCategoriesMenu />
      <WorkList works={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query WorksQuery {
    allJavascriptFrontmatter(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {published: {ne: false}}}
    ) {
      edges {
        node {
          ...WorkData
        }
      }
    }
  }
`

export default Works
