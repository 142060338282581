import React from 'react'
import styles from './menu.module.scss'
import Menu from '../Menu'

const worksMenuItems = [
  {
    title: 'All',
    href: '/works/',
  },
  {
    title: 'Web',
    href: '/works/web/',
  },
  {
    title: 'Identity',
    href: '/works/identity/',
  },
  {
    title: 'Print',
    href: '/works/print/',
  },
  /*
  {
    title: 'Illustration',
    href: '/works/illustration/',
  }*/
]

const WorkCategoriesMenu = () => (
  <Menu className={styles.menu} items={worksMenuItems} itemParentClassName=" " />
)

export default WorkCategoriesMenu
